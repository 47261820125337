
import { useTaskListBatchModeStore } from '@root/src/store/stores/gcb2/taskListBatchMode'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'
import { useConfirmStore } from '@root/src/store/stores/confirm'
import {
    computed,
    defineComponent,
    getCurrentInstance,
    onMounted,
    Ref,
    ref,
    ToRefs,
    watch as watchVue,
} from 'vue'
import { storeToRefs } from 'pinia'
import NoOneSelected from '@root/src/components/chisai/GCB2/sidebar/batchMode/NoOneSelected.vue'
import Message from '@root/src/components/chisai/GCB2/sidebar/batchMode/Message.vue'
import { MAKE_TASKLIST_ACTION } from '@root/src/store/chisai/GCB2/actionTypes'
import { COMMUNICATION_ERROR_CODES, TASKLIST_ACTION_TYPES, TASKLIST_COMMUNICATIONS_TYPES } from '@root/src/vars/GCB2'
import actions from '@root/src/components/chisai/GCB2/taskList/actions'
import { Project } from '@root/src/types/main'
import { CONNECTOR_TYPES } from '../../../vars/general'
import { massMailmessage, ModifiedMessageDH, sendMassMail } from '../../../api/mailing'
import messsages from '@dataheroes/messages'
import { Activation, IGCB2Connector, ManualCommunication } from '../../../types/GCB2'
import { ImportantError } from '../../../helpers/Error'
import { useUser } from '../../../hooks/useUser'
import api from '../../../api/api'
import HintIcon from '../../../components/HintIcon.vue'
import { declOfNum } from '../../../utils'
import { useHintStore } from '../../../store/stores/hint'
import { watch } from 'fs'
import checkConnectors from '../../../helpers/functions/checkConnectors'
import * as t from '@/store/chisai/GCB2/taskList/actionTypes'
import { communicationUpdate } from '@root/src/api/chisai/GCB2'
export default defineComponent({
    components: { NoOneSelected, Message, HintIcon },
    setup(props, {}) {
        const root = getCurrentInstance()!.proxy
        const { user } = useUser()
        const taskListBatchModeStore = useTaskListBatchModeStore()
        const hintStore = useHintStore()
        const confirmStore = useConfirmStore()
        const actionLoading = ref(false)
        const { closeSidebar } = useGcb2Store()
        // @ts-ignore И хотелось бы у кого ему научиться
        const {
            selectedCommunications,
        }: { selectedCommunications: Ref<ManualCommunication[]> } = storeToRefs(taskListBatchModeStore)
        const firstActivation = ref<Activation | null>(null)
        const batchModeSettings = ref<any | null>(null)

        const staticWrapperHeight = ref('0px')
        const hintId = 'knowledgeBase:1037'

        const counterText = computed(
            () =>
                `Выбрано:${selectedCommunications.value.length}, осталось ${messageLimit.value -
                    selectedCommunications.value.length}`
        )

        const changedMessagesCommunicationIds = new Set()

        const waBtnDisabled = computed(() => {
            const whatsappStatus = root.$store.state.whatsappStatus
            if (whatsappStatus?.statusSubsystem !== 'on') return false
            if (whatsappStatus.aliveStatus === false) return true
            if (!whatsappStatus || whatsappStatus?.statusSubsystem !== 'on') return false
            const btnStatus = whatsappStatus.aliveStatus === 'dead' ? true : false
            return btnStatus
        })

        const emptyListErrorMessage = computed(() =>
            !selectedCommunications.value.length ? 'Пользователи не выбраны' : false
        )
        const emptyTextMessageError = computed(() =>
            selectedCommunications.value.some(item => !item.textMessage || !item.textMessage.trim())
                ? 'Одно из сообщений является пустым'
                : false
        )
        const toShortPhoneError = computed(() =>
            selectedCommunications.value.some(
                item =>
                    item.hrefInfo
                        .filter(el => {
                            return el.connectorType === 'tel'
                        })[0]
                        .phone.replace(/\D/g, '').length < 11
            )
                ? 'Один из номеров телефонов длиной меньше 11 цифр'
                : false
        )

        const waDisabledError = computed(() => (waBtnDisabled.value ? 'Бот Whatsapp отключен' : false))

        const buttonsDisabled = computed(
            () =>
                emptyListErrorMessage.value ||
                emptyTextMessageError.value ||
                toShortPhoneError.value ||
                waDisabledError.value ||
                isCommunicationUpdate.value
        )
        const project = computed<Project>(() =>
            root.$store.getters.projectById(root.$router.currentRoute.params.id)
        )
        const isCommunicationUpdate = computed(() =>
            root.$store.getters.isCommunicationUpdate ? 'Дождитесь обновления расчетов' : false
        )
        const messagingConnector = computed(() =>
            taskListBatchModeStore.getMessagingConnector(project.value.id)
        )
        const defaultDailyMessageLimit = computed(
            () => batchModeSettings.value?.default_daily_limit_of_messages_items || 0
        )
        const defaultDailyMessageLimitText = computed(
            () =>
                `${defaultDailyMessageLimit.value} ${declOfNum(defaultDailyMessageLimit.value, [
                    'сообщение',
                    'сообщения',
                    'сообщений',
                ])}`
        )
        const activationSettingsLink = computed(
            () =>
                `/project/${project.value.id}/GCB2/activation?mode=edit&activationId=${firstActivation.value?.id}&highlightedStepId=7`
        )
        const openAddDailyLimitModal = () => {
            taskListBatchModeStore.setAddDailyLimitModalOpened(true)
        }
        const openHintSidebar = () => {
            hintStore.openHintSidebar(hintId)
        }
        const messageLimit = computed(() => taskListBatchModeStore.messageLimit(project.value.id))

        const onMessageChanged = (communicationId: number) => {
            changedMessagesCommunicationIds.add(communicationId)
        }
        const saveMessages = async () => {
            actionLoading.value = true
            const changedMessages = selectedCommunications.value.filter(el =>
                changedMessagesCommunicationIds.has(el.communicationId)
            )
            try {
                await actions.batchChangeTextMessage(
                    root,
                    project.value.id,
                    changedMessages.map(el => ({
                        projectId: project.value.id,
                        communicationId: el.communicationId,
                        textMessage: el.textMessage,
                    }))
                )
            } catch (err) {
                console.error(err)
            } finally {
                actionLoading.value = false
            }
        }
        const sendMessages = async () => {
            confirmStore.confirm({
                applyText: 'ОТПРАВИТЬ',
                text: `Вы точно хотите отправить сообщения выбранным (${selectedCommunications.value.length}) клиентам?`,
                onConfirm: async () => {
                    actionLoading.value = true
                    try {
                        const messages = messsages.getMessages({
                            projectId: project.value.id,
                            connectors: checkConnectors(project.value.connectors!),
                            communications: selectedCommunications.value,
                            project: project.value,
                            userId: user.value.sub,
                        })

                        let msgCopy: ModifiedMessageDH[] = JSON.parse(JSON.stringify(messages))

                        msgCopy = msgCopy.map(message => {
                            message.messages = message.messages.map(subMessage => {
                                let activationId = selectedCommunications.value.find(communication => {
                                    return communication.communicationId === subMessage.communicationId
                                }).activationId
                                return {
                                    ...subMessage,
                                    activationId,
                                }
                            })
                            return message
                        })
                        let sendMessageResponse = { error: null, data: null } as any

                        sendMessageResponse = await sendMassMail(msgCopy[0] as any)
                        const invalidMessages = sendMessageResponse.data.invalidMessages
                        const validMessages = sendMessageResponse.data.validMessages
                        const invalidSelectedCommunicationsIds = invalidMessages.map(
                            item => item.message.communicationId
                        )
                        const validSelectedCommunicationsIds = validMessages.map(item => item.communicationId)
                        const invalidSelectedCommunications = selectedCommunications.value.filter(item =>
                            invalidSelectedCommunicationsIds.includes(item.communicationId)
                        )
                        const validSelectedCommunications = selectedCommunications.value.filter(item =>
                            validSelectedCommunicationsIds.includes(item.communicationId)
                        )
                        const promises = []
                        if (sendMessageResponse.error || invalidMessages.length) {
                            promises.push(
                                actions.batchCommunicationError(
                                    root,
                                    project.value.id,
                                    invalidSelectedCommunications.map(el => ({
                                        projectId: project.value.id,
                                        communicationId: el.communicationId,
                                        clientId: el.clientId,
                                        userId: user.value.sub,
                                        userName: user.value.name,
                                        email: user.value.email,
                                        textMessage: el.textMessage,
                                        commType: TASKLIST_COMMUNICATIONS_TYPES.MESSAGE,
                                        messageError: true,
                                        messageErrorCode: COMMUNICATION_ERROR_CODES[invalidMessages.find(i => i.message.communicationId === el.communicationId).reason]
                                    }))
                                )
                            )
                        }
                        if (validSelectedCommunications.length) {
                            promises.push(
                                actions.batchInProgress(
                                    root,
                                    project.value.id,
                                    validSelectedCommunications.map(el => ({
                                        projectId: project.value.id,
                                        communicationId: el.communicationId,
                                        clientId: el.clientId,
                                        userId: user.value.sub,
                                        userName: user.value.name,
                                        email: user.value.email,
                                        textMessage: el.textMessage,
                                        commType: TASKLIST_COMMUNICATIONS_TYPES.MESSAGE
                                    }))
                                )
                            )
                        }
                        await Promise.all(promises)
                        await taskListBatchModeStore.consumeMessageLimit(
                            project.value.id,
                            validSelectedCommunications.length
                        )
                        if (invalidMessages.length) {
                            root.$store.commit(`GCB2/${t.APPEND_MESSAGE_ERRORS}`, invalidMessages)
                        }
                        if (sendMessageResponse.error) {
                            new ImportantError('batch mode send messages error', {
                                payload: { error: JSON.stringify(sendMessageResponse.error) },
                            })
                            // root.$store.dispatch('callNotify', 'Ошибка при отправке сообщений')
                            actionLoading.value = false
                            return
                        }
                    } catch (err) {
                        console.error(err)
                    } finally {
                        actionLoading.value = false
                        closeSidebar()
                        selectedCommunications.value = []
                        await communicationUpdate({ projectId: project.value.id })
                        localStorage.setItem('pkgStatus', '')
                    }
                },
            })
        }

        onMounted(async () => {
            staticWrapperHeight.value = String(+(root.$refs['static-header'] as any).clientHeight + 50) + 'px'
            const [_firstActivation, _batchModeSettings] = await Promise.all([
                api.gcb2.getFirstActivation(project.value.id).then(res => res.data!),
                api.massender
                    .getLimitSettings({
                        projectId: project.value.id,
                        connectorType: messagingConnector.value?.connectorType!,
                    })
                    .then(res => res.data!),
                taskListBatchModeStore.fetchSendMessageLimit(project.value.id),
            ])
            firstActivation.value = _firstActivation
            batchModeSettings.value = _batchModeSettings
        })

        return {
            selectedCommunications,
            counterText,
            buttonsDisabled,
            actionLoading,
            closeSidebar,
            defaultDailyMessageLimitText,
            sendMessages,
            onMessageChanged,
            saveMessages,
            messageLimit,
            staticWrapperHeight,
            openAddDailyLimitModal,
            defaultDailyMessageLimit,
            activationSettingsLink,
            hintId,
            openHintSidebar,
            emptyListErrorMessage,
            emptyTextMessageError,
            toShortPhoneError,
            isCommunicationUpdate
        }
    },
})
